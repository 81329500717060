.about-section {
  background-color: #EAE0D5;
  padding: 50px 0;
}

.about-section-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.about-section-title {
  color: #D4AF37;
  font-weight: 700;
  margin-bottom: 20px;
}

.about-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.about-item {
  background-color: #A97142;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.about-item h3 {
  color: #EAE0D5;
  margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-section {
    padding: 30px 0;
  }

  .about-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .about-item {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .about-section {
    padding: 20px 0;
  }

  .about-section-content {
    padding: 0 15px;
    text-align: center;
  }

  .about-list {
    grid-template-columns: 1fr; /* Stacks items vertically on small screens */
    gap: 15px;
  }

  .about-item {
    padding: 10px;
  }

  .about-item h3 {
    font-size: 1.2em;
  }
}

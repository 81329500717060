/* src/components/qcUs.css */
.qc-section {
  padding: 40px;
  background-color: #f5f5f5;
}

.qc-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.qc-section-title {
      color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.qc-section p {
  margin-bottom: 20px;
}

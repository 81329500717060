/* src/components/scUs.css */
.sc-section {
  padding: 40px;
  background-color: #f5f5f5;
}

.sc-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.sc-section-title {
    color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.sc-section p {
  margin-bottom: 20px;
}

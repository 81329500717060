/* src/components/ManufacturingConsulting.css */

.manufacturing-consulting-section {
  padding: 40px;
  background-color: #f9f9f9;
}

.manufacturing-consulting-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.manufacturing-consulting-section-title {
     color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.manufacturing-consulting-section p {
  margin-bottom: 20px;
}

.manufacturing-consulting-section ul {
  margin: 0;
  padding-left: 20px;
}

.manufacturing-consulting-section li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.manufacturing-consulting-section i.fa-check {
  margin-right: 10px;
  font-size: 1.2em;
}

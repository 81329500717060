/* src/components/elUs.css */
.el-section {
  padding: 40px;
  background-color: #f5f5f5;
}

.el-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.el-section-title {
     color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.el-section p {
  margin-bottom: 20px;
}

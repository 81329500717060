      .footer{
    min-height: 100vh;
    width: 100%;
    background: #2C3539;
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 70px 0;
}
        
        
.footer-row{
    margin-top: 5%;
    display: flex;
    justify-content: space-between;
   
}
    
    .footer-row p{
    
     color: #D4AF37;
    }
  
.footer-col{
    width: 25%;
    padding: 0 15px;
}

.footer-container {
    
    max-width: 1170px;
    margin: auto;
}


.footer-container ul li{
    list-style: none;

    
}
        .footer-col h4{
   font-size: 18px;
    color:  #D4AF37;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
}

.footer-col h4:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    background-color: brown;
    height: 2px;
    box-sizing: border-box;
    width: 50px;

}


.footer-col ul li:not(last-child){
    margin-bottom: 10px;
}

.footer-col ul li a{
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
    
}

.footer-col ul li a:hover{
    color: #ffffff;
    padding-left: 8px;
    
}

.footer-col .social-links a{
    
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: #D4AF37;
    margin: 0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.3s ease;
    
}
.footer-col .social-links a:hover{
    color: #941B0C;
    background-color: #EB5406;
}


.footer-col dl dt:not(last-child){
    margin-bottom: 10px;
    color: #ffffff;
}

.footer-col dl dt{
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
    
}

.footer-col dl dt:hover{
    color: #ffffff;
    padding-left: 8px;
    
}

        
@media(max-width: 767px){
    .footer-row{
        flex-direction: column;
    }
    .footer-col{
        width: 50%;
        margin-bottom: 30px;
        
    }
    
}

@media(max-width: 570px){
    
    .footer-col{
        width: 100%;
    }
    
}
        @media only screen and (max-width: 480px) {
            
 .footer-col{
        width: 100%;
    }
        }

    
    
/* General container styling */
.container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

/* Header styling */
.header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
}

/* Section styling */ 
.section {
  margin-bottom: 20px;
}

.section h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

/* Form group styling */
.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.form-group input,
.form-group textarea {
  flex: 2;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: none;
}

/* Table styling */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f1f1f1;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.table td input {
  width: 100%;
  padding: 6px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Button styling */
button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Totals styling */
.totals {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totals div {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.totals input {
  width: 150px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Textarea styling */
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

/* Responsive design */
@media (max-width: 768px) {
  .container {
    width: 95%;
    padding: 10px;
  }

  .form-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .form-group label {
    margin-bottom: 5px;
  }

  .totals {
    flex-direction: column;
  }

  .totals div {
    margin-bottom: 10px;
  }
}

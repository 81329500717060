/* src/components/FieldWeServe.css */


.field-section {
  padding: 50px 0;
  /* background-color: #941B0C; */
}

.field-section-content {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
}

.field-section-title {
  color: #D4AF37;
  font-weight: 700;
  margin-bottom: 20px;
}

.field-list {
  display: flex;
  flex-wrap: wrap;
}

.field-item {
  flex: 1 1 100%; /* Each field item takes up full width */
  margin-bottom: 20px; /* Add margin between items */
  display: flex; /* Use flexbox for layout */
  background-color: #EAE0D5;
  border-radius: 10px;
}

.field-item img {
  width: 30%; /* Set image width */
  height: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Add margin between image and content */
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.field-content {
  flex: 1; /* Allow content to take up remaining space */
}

.field-content h3 {
  text-align: center; /* Center-align header */
  color: #A97142;
}

.field-content p,
.field-content ul,
.field-content li {
  color: #5941A9;
  text-align: left; /* Align text left */
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .field-item {
    flex-direction: column; /* Stack image and content vertically */
    align-items: center; /* Center-align on mobile */
    text-align: center; /* Center-align text on mobile */
  }

  .field-item img {
    width: 100%; /* Make image full width on mobile */
    border-radius: 10px 10px 0 0; /* Adjust border-radius for mobile */
    margin-right: 0; /* Remove margin between image and content */
    margin-bottom: 20px; /* Add margin below image */
  }

  .field-content h3 {
    font-size: 1.5em; /* Adjust heading size on mobile */
  }

  .field-content p,
  .field-content ul,
  .field-content li {
    font-size: 1em; /* Adjust paragraph size on mobile */
    text-align: center; /* Center-align text on mobile */
  }
}

@media screen and (max-width: 480px) {
  .field-section {
    padding: 20px; /* Reduce padding on smaller mobile screens */
  }

  .field-item {
    margin-bottom: 15px; /* Reduce space between items */
  }

  .field-content h3 {
    font-size: 1.3em; /* Further adjust heading size */
  }

  .field-content p,
  .field-content ul,
  .field-content li {
    font-size: 0.9em; /* Adjust font size for smaller screens */
  }
}



/*
.field-section {
  padding: 40px;
  background-color: #f9f9f9;
}

.field-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.field-section-title {
  font-size: 2em;
  margin-bottom: 20px;
}

.field-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.field-item {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
}

.field-item img {
  width: 100%;
  height: auto;
  display: block;
}

.field-content {
  padding: 20px;
}

.field-content h3 {
  margin-top: 0;
}

.field-content p {
  margin-bottom: 0;
}
    */
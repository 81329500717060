.location-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.location-card {
  background-color: white;
  padding: 20px;
  width: 45%;  /* For desktop view */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  text-align: left;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.location-card:hover {
  transform: translateY(-5px);
}

.location-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 8px 8px 0 0; /* Rounded corners for the top */
}

/* src/components/Chatbot.css */


 
    .chatbot {
    position: fixed;
    bottom: 10px; /* Adjust the distance from the bottom as needed */
    right: 0px; /* Adjust the distance from the right as needed */
    z-index: 1000; /* Ensure the chatbot stays above other content */
}


.chatbot img {
    width: 120px; /* Adjust the width of the image as needed */
   /*  height: 200px; Adjust the height of the image as needed */
    margin-right: 0px; /* Adjust the margin between the image and chatbot interface */
}

.chatbot img:hover {
     animation: shake 0.5s;
  animation-iteration-count: infinite;
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.form-popup {
  position: fixed;
  bottom: 70px;
  right: 20px;
  width: 300px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
}

.form-popup h3 {
  margin-top: 0;
}

.form-popup .error {
  color: red;
  margin-bottom: 10px;
}

.form-popup input, .form-popup select, .form-popup textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-popup .btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.form-popup .btn:hover {
  background-color: #0056b3;
}

.form-popup .btn.cancel {
  background-color: #6c757d;
}

.form-popup .btn.cancel:hover {
  background-color: #5a6268;
}

.ml-section {
  padding: 40px;
  background-color: #f5f5f5;
}

.ml-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.ml-section-title {
  color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.ml-section p {
  margin-bottom: 20px;
}

.ml-section ul {
  margin: 0;
  padding-left: 20px;
}

.ml-section li {
  margin-bottom: 10px;
}

.subsection-title {
  font-size: 2em;
  color: #A97142;  /* Subsection title color */
  margin-bottom: 20px;
  text-align: center;
}

.location-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.location-card {
  background-color: white;
  padding: 20px;
  width: 45%;  /* For desktop view */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  text-align: left;
  transition: transform 0.3s ease;
}

.location-card:hover {
  transform: translateY(-5px);
}

.location-card h3 {
  color: #2C3539;
  font-size: 1.5em;
}

.location-card p {
  color: #555;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .location-card {
    width: 100%;  /* Adjust for tablets and small screens */
  }
}

@media screen and (max-width: 768px) {
  .ml-section {
    padding: 20px;
  }

  .ml-section-title, .subsection-title {
    font-size: 1.8em;  /* Adjust for smaller screens */
  }

  .location-grid {
    flex-direction: column;  /* Stack cards vertically */
    align-items: center;
  }

  .location-card {
    width: 100%;  /* Full width for mobile screens */
    margin-bottom: 20px;
  }

  .location-card h3 {
    font-size: 1.2em;  /* Adjust heading size */
  }

  .location-card p {
    font-size: 1em;  /* Adjust paragraph size */
  }
}

@media screen and (max-width: 480px) {
  .ml-section-title, .subsection-title {
    font-size: 1.5em;  /* Smaller heading size for mobile */
  }

  .ml-section {
    padding: 10px;  /* Reduce padding for mobile */
  }

  .location-card {
    padding: 15px;
  }
}

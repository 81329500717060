.contact-section {
  padding: 50px 0;
  background-color: #EAE0D5;
}

.contact-section-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.contact-section-title {
  color: #D4AF37;
  font-weight: 700;
  margin-bottom: 20px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px; /* Space between contact info and form */
}

.contact-info {
  background-color: #B76734; /* Dark background color */
  color: #EAE0D5; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  width: 48%; /* Takes up half the space next to the form */
}

.contact-info h3 {
  color: #F2F2F2; /* Light text color */
  margin-bottom: 10px;
}

.contact-info p {
  margin-bottom: 5px;
}

.contact-form {
  background-color: #941B0C; /* Dark background color */
  color: #F2F2F2; /* Light text color */
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  width: 48%; /* Takes up half the space next to the contact info */
}

.contact-form h3 {
  margin-bottom: 10px;
  color: #F2F2F2; /* Light text color */
}

.contact-form label {
  display: block;
  margin-bottom: 10px;
  color: #F2F2F2; /* Light text color */
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: calc(100% - 20px); /* Subtract padding from total width */
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.contact-form textarea {
  resize: vertical;
}

.contact-form button {
  width: 100%;
  background-color: #226F54; /* Dark background color */
  color: #F2F2F2; /* Light text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #0A0908; /* Darker background color on hover */
}

/* Mobile-specific adjustments */
@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column; /* Stack contact info and form vertically */
    gap: 20px;
  }

  .contact-info, .contact-form {
    width: 100%; /* Make both take full width on mobile */
  }

  .contact-info, .contact-form button {
    margin-bottom: 20px; /* Add spacing between sections on mobile */
  }
}

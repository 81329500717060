.fullpage {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.section {
  margin-bottom: 40px;
}

.section-header-image {
  display: block;
  margin: 0 auto;
  width: 80%;
  height: auto;
}

.section-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.content-left, .content-right {
  flex: 1;
  padding: 20px;
}

.content-left h2, .content-right h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: #2e64a6;
}

.content-left p, .content-right p {
  font-size: 1.2em;
  color: #555;
}

.images-right, .images-left {
  flex: 1;
  display: flex;
  gap: 20px;
}

.images-right img, .images-left img {
  width: 48%;
  height: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.section:nth-child(even) .section-content {
  flex-direction: row-reverse;
}

.book-now-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.book-now-button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .section-content {
    flex-direction: column;
    align-items: center;
  }

  .content-left, .content-right {
    padding: 10px;
    text-align: center;
  }

  .images-right img, .images-left img {
    width: 100%; /* Ensure the images take up full width on smaller screens */
  }

  .section-header-image {
    width: 100%; /* Ensure the header image also takes up full width */
  }
}

/* Stack images into two rows on smaller screens */
@media screen and (max-width: 768px) {
  .images-right, .images-left {
    flex-wrap: wrap; /* Allow images to wrap to the next row */
  }

  .images-right img, .images-left img {
    width: 100%; /* Each image takes full width */
    margin-bottom: 10px; /* Add margin between rows */
  }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 480px) {
  .content-left h2, .content-right h2 {
    font-size: 1.5em; /* Reduce the heading font size on mobile */
  }

  .content-left p, .content-right p {
    font-size: 1em; /* Reduce the paragraph font size on smaller screens */
  }

  .book-now-button {
    padding: 8px 16px; /* Adjust button size for mobile screens */
    font-size: 0.9em;
  }

  .section-content {
    padding: 10px; /* Reduce padding to fit smaller screen sizes */
  }
}

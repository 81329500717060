/* src/components/seUs.css */
.ctv-section {
  padding: 40px;
  background-color: #f5f5f5;
}

.ctv-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.ctv-section-title {
         color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.ctv-section p {
  margin-bottom: 20px;
}

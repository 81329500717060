.supply-section {
  padding: 40px;
  background-color: #f9f9f9;
}

.supply-section-content {
  max-width: 1200px;
  margin: 0 auto;
}

.supply-section-title {
  color: #D4AF37; 
  font-size: 2em;
  margin-bottom: 20px;
}

.supply-section p {
  margin-bottom: 20px;
}

.supply-section ul {
  margin: 0;
  padding-left: 20px;
}

.supply-section li {
  margin-bottom: 10px;
}

.subsection-title {
  font-size: 2em;
  color: #A97142;  /* Title color */
  margin-bottom: 20px;
  text-align: center;
}

.supple-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.supple-card {
  background-color: white;
  padding: 10px;
  width: 30%;  /* For desktop view */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
  text-align: left;
  transition: transform 0.3s ease;
}

.supple-card:hover {
  transform: translateY(-5px);
}

.supple-card h3 {
  color: #2C3539;
  font-size: 1.5em;
}

.supple-card p {
  color: #555;
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .supple-card {
    width: 45%;  /* Adjust for tablets */
  }
}

@media screen and (max-width: 768px) {
  .supply-section {
    padding: 20px;
  }

  .supply-section-title, .subsection-title {
    font-size: 1.8em;  /* Adjust for smaller screens */
  }

  .supple-card {
    width: 100%;  /* Full width for mobile screens */
    margin-bottom: 20px;
  }

  .supple-grid {
    flex-direction: column;
    align-items: center;  /* Center the items */
  }

  .supple-card h3 {
    font-size: 1.2em;  /* Adjust heading size */
  }

  .supple-card p {
    font-size: 1em;  /* Adjust paragraph size */
  }
}

@media screen and (max-width: 480px) {
  .supply-section-title, .subsection-title {
    font-size: 1.5em;  /* Smaller heading size for mobile */
  }

  .supply-section {
    padding: 10px;  /* Reduce padding for mobile */
  }

  .supple-card {
    padding: 8px;
  }
}

body {
  background-color: #F2F2F2;
  color: #333333;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  padding: 50px;
  flex-direction: row; /* Default for larger screens */
    
}

.hero-full-height {
  height: 95vh;
}

.hero-content {
  max-width: 50%;
  color: #D4AF37;
}

.hero-content h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2em;
  color: #ffffff;
}

.error { color: red; 

} 

/* Quote Form Section */
.quote-form {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  width: 40%;
}

.quote-form h2 {
  color: #ffffff;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.quote-form form {
  display: flex;
  flex-direction: column;
}

.quote-form label {
  color: #ffffff;
  margin-bottom: 10px;
}

.quote-form input[type="text"],
.quote-form input[type="email"],
.quote-form input[type="tel"],
.quote-form select,
.quote-form textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
}

.quote-form textarea {
  resize: vertical;
}

.quote-form button {
  background-color: #226F54;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quote-form button:hover {
  background-color: #0A0908;
}

.container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 0px 0;
}

/* Attachment Button */
#attachment {
  background-color: #226F54;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}


.phoneNumberContainer {
  display: flex;
  align-items: center;
}

.phoneNumberContainer select {
  width: 80px; /* Adjust width as needed */
  margin-right: 5px; /* Adjust spacing */
}

.phoneNumberContainer input {
  flex: 1; /* Allow input to take the remaining space */
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Hero section for tablets/small devices */
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .hero-content {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .hero-content h1 {
    font-size: 2em;
  }

  .hero-content p {
    font-size: 1em;
  }

  /* Quote form for tablets/small devices */
  .quote-form {
    width: 100%;
  }
}

@media (max-width: 480px) {
  /* Hero section for mobile */
  .hero {
    padding: 10px;
  }

  .hero-content h1 {
    font-size: 1.8em;
  }

  .hero-content p {
    font-size: 0.9em;
  }

  /* Quote form for mobile */
  .quote-form {
    padding: 15px;
  }

  .quote-form h2 {
    font-size: 1.2em;
  }

  /* Adjust button padding for mobile */
  .quote-form button {
    padding: 8px 16px;
  }

  #attachment {
    padding: 8px 16px;
  }
}
